.fontStyle {
    font-family: 'Baloo Paaji 2', system-ui;
}

.MuiButton-label {
    font-family: 'Baloo Paaji 2', system-ui;
    font-size: initial;
}

.heading {
    font-weight: 700;
}

.subheading {
    font-weight: 300;
}

#landscape {
    display: block;
}

#landscapeFlex {
    display: flex !important;
}
#mobile {
    display: none;
}

/* On screens that are 600px wide or less, the background color is olive */
@media screen and (max-width: 600px) {
    #landscape {
        display: none !important;
    }
    #landscapeFlex {
        display: none !important;
    }

    #mobile {
        display: block !important;
    }
}

.footer {
    z-index: -101;
    position: fixed;
    width: 100%;
    bottom: 0px;
    background: #eeeeee;
    text-align: center;
}

.footerContent {
    /*position: relative;*/
    /*top: 23%;*/
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
